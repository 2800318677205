module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-193469561-1","head":true,"anonymize":true,"pageTransitionDelay":0,"variationId":"0","defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"oyyou-comics.com","respectDNT":false,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oyyou Comics","short_name":"Oyyou Comics","start_url":"/","background_color":"#202124","theme_color":"#d4d4d4","display":"standalone","icon":"src/images/Logo.png","crossOrigin":"use-credentials","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"89d7f012288155844852b4d2b90f0c9b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
